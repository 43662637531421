import { GlobalStyles as MUIGlobalStyles } from '@mui/material';
import React from 'react';
import { isMobile } from 'react-device-detect';

export default function GlobalStyles() {
  const inputGlobalStyles = (
    <MUIGlobalStyles
      styles={(theme) => ({
        '*': {
          boxSizing: 'border-box',
        },
        html: {
          margin: 0,
          padding: 0,
          WebkitOverflowScrolling: 'touch',
          // overflowY: 'scroll',
          textRendering: 'optimizeLegibility',
          scrollBehavior: 'smooth',
          // Hide scrollbar on mobile devices
          ...(isMobile && {
            '&::-webkit-scrollbar': {
              display: 'none',
            },
            scrollbarWidth: 'none', // Firefox
            msOverflowStyle: 'none', // IE 10+
          }),
          // maxWidth: '90vw',
          overflowX: 'hidden',
          overflowY: 'auto',
          // overflowX: 'hidden',
          // overflow: 'hidden',
        },
        body: {
          // maxWidth: '90vw',
          margin: 0,
          padding: 0,
          position: 'relative',
          scrollBehavior: 'smooth',
          '&::-webkit-scrollbar': {
            // width: '12px',
            background: 'transparent !important',
          },
          '&::-webkit-scrollbar-track': {
            // width: '12px',
            background: 'transparent',
          },
          '&::-webkit-scrollbar-thumb': {
            background: isMobile ? 'transparent !important' : '#424242',
            backgroundColor: isMobile ? 'transparent !important' : '#424242',
            borderRadius: '20px',
            border: '3px solid transparent',
            backgroundClip: 'content-box',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: isMobile ? 'transparent !important' : '#535353',
          },
          // Hide scrollbar on mobile devices
          ...(isMobile && {
            '&::-webkit-scrollbar': {
              // display: 'none',
            },
            // scrollbarWidth: 'none', // Firefox
            // msOverflowStyle: 'none', // IE 10+
          }),
          overflow: 'hidden',
        },
        '#__next': {
          width: '100%',
          height: '100%',
          overflow: 'hidden',
        },
        input: {
          '&[type=number]': {
            MozAppearance: 'textfield',
            '&::-webkit-outer-spin-button': {
              margin: 0,
              WebkitAppearance: 'none',
            },
            '&::-webkit-inner-spin-button': {
              margin: 0,
              WebkitAppearance: 'none',
            },
          },
        },
        img: {
          display: 'block',
          maxWidth: '100%',
        },
        ul: {
          margin: 0,
          padding: 0,
        },
        '.MuiTextField-root': {
          backgroundColor: 'rgba(0,0,0,0.08)',
          borderColor: 'rgba(255,255,255,0.08) !important',
          transition: 'all 0.3s ease-in-out !important',

          '& label.Mui-focused': {
            color: theme.palette.primary.main,
          },
          '& .MuiInput-underline:after': {
            borderBottomColor: 'rgba(255,255,255,0.08) !important',
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              // borderColor: 'rgba(255,255,255,0.08) !important',
            },
            '&:hover fieldset': {
              // borderColor: 'rgba(255,255,255,0.08) !important',
              border: '1px solid rgba(255,255,255,0.38) !important',
            },
            '&.Mui-focused fieldset': {
              backgroundColor: 'rgba(0,0,0,0.16) !important',
              // borderColor: 'rgba(255,255,255,0.38) !important',
            },
          },
        },
        '.MuiPaper-root': {},
        '.MuiButton-root': {
          color: theme.palette.common.white,
          backgroundColor: 'rgba(255,255,255,0.08)',
          border: '1px solid rgba(255,255,255,0.32) !important',
          '&.MuiButton-containedPrimary': {
            backgroundColor: 'rgba(255,255,255,0.04)',
            '&:hover': {
              backgroundColor: 'rgba(0,0,0,0.04)',
            },
          },
          '&:hover': {
            backgroundColor: 'rgba(255,255,255,0.04)',
            border: '1px solid rgba(255,255,255,0.48) !important',
            boxShadow: 'none !important',
          },
        },
        // 'MuiButtonBase-root': { backgroundColor: 'rgba(0,0,0,0.08) !important' },
        '.desktop-bottom-sheet': {
          // backgroundColor: 'background !important',
          // boxShadow: 'none !important',
          // borderTop: `solid 1px ${theme.palette.divider} !important`,
          border: '1px solid rgba(255,255,255,0.08) !important',
          // backgroundColor: 'rgba(0,0,0,0.08) !important',
          backgroundColor: theme.palette.background.glas,
          backdropFilter: 'blur(16px) !important',
          webkitBackdropFilter: 'blur(16px) !important',
          boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 2px 0px, rgba(0, 0, 0, 0.5) 0px 20px 25px 0px',
          WebkitBackdropFilter: 'blur(5px) !important',
        },
        '.MuiBottomNavigationAction-root': {
          transition: 'all 0.3s ease-in-out !important',
          padding: '0px !important',
          // minWidth: isMobile ? '60px !important' : '80px !important',
          color: `${theme.palette.grey[400]} !important`,
          borderRadius: '10px !important',
          '&:hover': {
            transform: 'scale(1.1)',
            color: `${theme.palette.text.primary} !important}`,
          },
          '.MuiBottomNavigationAction-root &.Mui-selected': {
            // fontSize: '0.75rem !important',
            color: 'inherit !important',
          },
        },
        '.MuiBottomNavigationAction-label': {
          '&.Mui-selected': {
            // override the selected class within BottomNavigationAction
            fontSize: 'inherit !important', // reset the color
            transform: 'none !important', // reset the transform
          },
        },
      })}
    />
  );

  return inputGlobalStyles;
}
