import { alpha } from '@mui/system';

// ----------------------------------------------------------------------

// SETUP COLORS

const GREY = {
  0: '#FFFFFF',
  100: '#F5F5F5',
  200: '#E0E0E0',
  300: '#BDBDBD',
  400: '#9E9E9E',
  500: '#7E7E7E',
  600: '#616161',
  700: '#424242',
  800: '#191919', // More in line with #0D0D0D
  900: '#0D0D0D', // Exactly your shade
};

const PRIMARY = {
  lighter: '#D0E8FF', // Softer blue for lighter variants
  light: '#90CAF9', // Light Blue
  main: '#2196F3', // Blue
  dark: '#1976D2', // Dark Blue
  darker: '#0D47A1', // Richer Blue
  contrastText: '#FFFFFF',
};

const SECONDARY = {
  lighter: '#FFE0B2', // Soft Amber for lighter variants
  light: '#FFB74D', // Light Orange
  main: '#FF9800', // Orange
  dark: '#F57C00', // Dark Orange
  darker: '#E65100', // Richer Orange
  contrastText: '#FFFFFF',
};

const INFO = {
  lighter: '#B3E5FC', // Light Info Blue
  light: '#81D4FA', // Info Blue
  main: '#29B6F6', // Standard Info Blue
  dark: '#039BE5', // Dark Info Blue
  darker: '#0277BD', // Richer Info Blue
  contrastText: '#FFFFFF',
};

const SUCCESS = {
  lighter: '#C8E6C9', // Light Green
  light: '#81C784', // Green
  main: '#4CAF50', // Standard Green
  dark: '#388E3C', // Dark Green
  darker: '#1B5E20', // Richer Green
  contrastText: '#FFFFFF',
};

const WARNING = {
  lighter: '#FFF3E0', // Light Amber
  light: '#FFB74D', // Amber
  main: '#FF9800', // Orange
  dark: '#F57C00', // Dark Orange
  darker: '#E65100', // Richer Orange
  contrastText: GREY[800],
};

const ERROR = {
  lighter: '#FFCDD2', // Light Red
  light: '#E57373', // Red
  main: '#F44336', // Standard Red
  dark: '#D32F2F', // Dark Red
  darker: '#B71C1C', // Richer Red
  contrastText: '#FFFFFF',
};

const COMMON = {
  common: { black: '#000000', white: '#FFFFFF' },
  primary: PRIMARY,
  secondary: SECONDARY,
  info: INFO,
  success: SUCCESS,
  warning: WARNING,
  error: ERROR,
  grey: GREY,
  divider: alpha(GREY[500], 0.24),
  action: {
    hover: alpha(GREY[500], 0.08),
    selected: alpha(GREY[500], 0.16),
    disabled: alpha(GREY[500], 0.8),
    disabledBackground: alpha(GREY[500], 0.24),
    focus: alpha(GREY[500], 0.24),
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

export default function palette(themeMode: 'light' | 'dark') {
  const light = {
    ...COMMON,
    mode: 'light',
    text: {
      primary: GREY[800],
      menu: GREY[700],
      secondary: GREY[600],
      disabled: GREY[500],
    },
    background: { paper: '#FFFFFF', default: '#FAFAFA', neutral: GREY[200] },
    action: {
      ...COMMON.action,
      active: GREY[600],
    },
  };

  const dark = {
    ...COMMON,
    mode: 'dark',
    text: {
      primary: '#FFFFFF',
      secondary: GREY[500],
      disabled: GREY[600],
    },
    background: {
      paper: GREY[800],
      default: GREY[900],
      neutral: alpha(GREY[500], 0.16),
      glas: alpha(GREY[900], 0.76),
    },
    action: {
      ...COMMON.action,
      active: GREY[500],
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            '&:hover': {
              boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.3)', // Your hover shadow here
            },
          },
        },
      },
    },
  };

  return themeMode === 'light' ? light : dark;
}
