import { enUS, deDE, ukUA, ruRU, esES, frFR, zhCN } from '@mui/material/locale';

export const allLangs = [
  {
    label: 'English',
    value: 'en',
    systemValue: enUS,
    icon: '/assets/icons/flags/ic_flag_en.svg',
  },
  {
    label: 'Deutsch',
    value: 'de',
    systemValue: deDE,
    icon: '/assets/icons/flags/ic_flag_de.svg',
  },
  {
    label: 'Українська',
    value: 'uk',
    systemValue: ukUA,
    icon: '/assets/icons/flags/ic_flag_ua.svg',
  },
  {
    label: 'Español',
    value: 'es',
    systemValue: esES,
    icon: '/assets/icons/flags/ic_flag_es.svg',
  },
  {
    label: 'Français',
    value: 'fr',
    systemValue: frFR,
    icon: '/assets/icons/flags/ic_flag_fr.svg',
  },
  // Mandarin Chinese
  {
    label: '中文',
    value: 'zh',
    systemValue: zhCN,
    icon: '/assets/icons/flags/ic_flag_cn.svg',
  },
  {
    label: 'Русский',
    value: 'ru',
    systemValue: ruRU,
    icon: '/assets/icons/flags/ic_flag_ru.svg',
  },
];

export const defaultLang = allLangs[0]; // Default to English
