import { Capacitor } from '@capacitor/core';
import { Device } from '@capacitor/device';
import { changeLanguage as i18nChangeLanguage } from './i18n';
import { allLangs, defaultLang } from './config-lang';

let isLanguageInitialized = false;

export const initializeLanguage = async () => {
  if (isLanguageInitialized) {
    // console.log('Language initialization skipped');
    return;
  }

  // console.log('Initializing language');

  let newLang;

  // Check if running in a Capacitor app
  if (Capacitor.isNativePlatform()) {
    // Get the device language for iOS or Android
    const { value } = await Device.getLanguageCode();
    const supportedLang = allLangs.find((lang) => lang.value === value);
    newLang = supportedLang ? value : defaultLang.value;
  } else {
    // Running in a web browser
    const storedLang = localStorage.getItem('i18nextLng');
    if (storedLang) {
      newLang = storedLang;
    } else {
      const browserLang = navigator.language.split('-')[0];
      const supportedLang = allLangs.find((lang) => lang.value === browserLang);
      newLang = supportedLang ? browserLang : defaultLang.value;
    }
  }

  await i18nChangeLanguage(newLang);
  localStorage.setItem('i18nextLng', newLang); // Update localStorage
  isLanguageInitialized = true;
  // console.log('Language initialized');
};
