import { Theme } from '@mui/material/styles';
import { ErrorIcon, InfoIcon, SuccessIcon, WarningIcon } from './CustomIcons';

// ----------------------------------------------------------------------

const COLORS: string[] = ['info', 'success', 'warning', 'error'];

interface OwnerState {
  severity?: string;
  variant?: 'standard' | 'filled' | 'outlined';
}

export default function Alert(theme: Theme) {
  const isLight = theme.palette.mode === 'light';

  const rootStyle = (ownerState: OwnerState) => {
    const standardVariant = ownerState.variant === 'standard';
    const filledVariant = ownerState.variant === 'filled';
    const outlinedVariant = ownerState.variant === 'outlined';

    const isPaletteColor = (
      item: any
    ): item is {
      darker?: string;
      lighter?: string;
      main?: string;
      contrastText?: string;
      dark?: string;
      light?: string;
    } => typeof item === 'object' && ('darker' in item || 'lighter' in item);

    const colorStyle = COLORS.map((color) => {
      const paletteColor = theme.palette[color as keyof typeof theme.palette];

      if (isPaletteColor(paletteColor)) {
        return {
          ...(ownerState.severity === color && {
            // STANDARD
            ...(standardVariant && {
              color: paletteColor[isLight ? 'darker' : 'lighter'],
              backgroundColor: paletteColor[isLight ? 'lighter' : 'darker'],
              '& .MuiAlert-icon': {
                color: paletteColor[isLight ? 'main' : 'light'],
              },
            }),
            // FILLED
            ...(filledVariant && {
              color: paletteColor.contrastText,
              backgroundColor: paletteColor.main,
            }),
            // OUTLINED
            ...(outlinedVariant && {
              color: paletteColor[isLight ? 'dark' : 'light'],
              border: `solid 1px ${paletteColor.main}`,
              '& .MuiAlert-icon': {
                color: paletteColor.main,
              },
            }),
          }),
        };
      }
    });

    return [...colorStyle];
  };

  return {
    MuiAlert: {
      defaultProps: {
        iconMapping: {
          info: <InfoIcon component={'svg'} />,
          success: <SuccessIcon component={'svg'} />,
          warning: <WarningIcon component={'svg'} />,
          error: <ErrorIcon component={'svg'} />,
        },
      },

      styleOverrides: {
        root: ({ ownerState }: { ownerState: OwnerState }) => rootStyle(ownerState),
        icon: {
          opacity: 1,
        },
      },
    },
    MuiAlertTitle: {
      styleOverrides: {
        root: {
          marginBottom: theme.spacing(0.5),
        },
      },
    },
  };
}
