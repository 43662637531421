export class AppError implements Error {
  name: string = 'AppError';
  message: string;
  stack?: string | undefined;
  cause?: unknown;
  translation: string;

  constructor(translation: string, message?: string) {
    this.message = message || '';
    this.translation = translation;
  }
}
