// ----------------------------------------------------------------------

export default function Breadcrumbs(theme: { spacing: (arg0: number, arg1: number | undefined) => any; typography: { body2: any; }; }) {
  return {
    MuiBreadcrumbs: {
      styleOverrides: {
        separator: {
          marginLeft: theme.spacing(2,2),
          marginRight: theme.spacing(2,2),
        },
        li: {
          display: 'inline-flex',
          margin: theme.spacing(0.25, 0),
          '& > *': {
            ...theme.typography.body2,
          },
        },
      },
    },
  };
}
