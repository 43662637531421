import { HttpErrorCodes } from '@models/common';

export class HttpApiError extends Error {
  constructor(
    public errorCode: HttpErrorCodes,
    message?: string,
    public httpCode: number = 400
  ) {
    super(message);
    Object.setPrototypeOf(this, HttpApiError.prototype);
  }

  static Create = (errorCode: HttpErrorCodes, message?: string, httpCode: number = 400) => new HttpApiError(errorCode, message, httpCode);

  toJSON() {
    return {
      status: this.httpCode,
      message: this.message,
      errorCode: this.errorCode,
    };
  }
}
