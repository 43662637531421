import useLocales from '@/locales/useLocales';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import React from 'react';

interface LoadingOverlayProps {
  message?: string;
  description?: string;
}

const LoadingOverlay: React.FC<LoadingOverlayProps> = () => {
  const { translate } = useLocales();
  return (
    <Overlay>
      <LoadingContent>
        <TextContainer>
          <AnimatedSymbol>⚡</AnimatedSymbol>
          <LoadingMessage>{translate('app.noInternetConnection')}</LoadingMessage>
          <LoadingDescription>{translate('app.noInternetConnectionDescription')}</LoadingDescription>
        </TextContainer>
      </LoadingContent>
    </Overlay>
  );
};

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`;

const LoadingContent = styled.div`
  color: white;
  text-align: center;
  padding: 2em;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TextContainer = styled.div`
  position: relative;
`;

const LoadingMessage = styled.div`
  font-size: 1.8em;
  font-weight: bold;
  margin-bottom: 0.5em;
`;

const LoadingDescription = styled.div`
  font-size: 1.2em;
`;

const animateSymbol = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
`;

const AnimatedSymbol = styled.div`
  font-size: 3em;
  animation: ${animateSymbol} 2s infinite;
  position: absolute;
  top: -1.5em; /* Adjust positioning as needed */
  left: 50%;
  transform: translateX(-50%);
`;

export default LoadingOverlay;
