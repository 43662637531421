import React, { useEffect } from 'react';
import Script from 'next/script';
import { useRouter } from 'next/router';

interface TrackerProps {
  ids: string[];
}

const BaseTracker: React.FC<TrackerProps> = ({ ids }) => {
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = () => {
      const currentPath = window.location.pathname + window.location.search;

      ids.forEach((id) => {
        if ((window as any).gtag) {
          (window as any).gtag('config', id, {
            url: currentPath,
          });
        }
      });
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router, ids]);

  return (
    <>
      {ids.map((id) => (
        <React.Fragment key={id}>
          <Script src={`https://www.googletagmanager.com/gtag/js?id=${id}`} strategy="afterInteractive" />
          <Script id={`google-analytics-${id}`} strategy="afterInteractive">
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${id}');
            `}
          </Script>
        </React.Fragment>
      ))}
    </>
  );
};

export default BaseTracker;
