import 'simplebar-react/dist/simplebar.min.css';
import 'react-lazy-load-image-component/src/effects/blur.css';
import dynamic from 'next/dynamic';
import { CacheProvider, EmotionCache } from '@emotion/react';
import { Provider as ReduxProvider } from 'react-redux';
import { store } from '@/redux/store';
import createEmotionCache from '@/utils/createEmotionCache';
import { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import { safeAreaInsetBottomAtom, safeAreaInsetTopAtom } from '../atoms/_app.states';
import { SafeArea } from 'capacitor-plugin-safe-area';
import i18n from '@/locales/i18n'; // Ensure i18n is imported
import { SettingsProvider } from '@/components/settings/SettingsContext';
import ThemeProvider from '../theme';
import './_app.css';
import BaseTracker from '@/components/BaseTracker';
import { initializeLanguage } from '@/locales/initializeLanguage';
import { Capacitor } from '@capacitor/core';
import { Network } from '@capacitor/network';
import NoInternetConnection from '@/components/loading-screen/noInternetConnection';
// import MaintenanceMessage from '@/components/MaintenanceMessage';

// Dynamically import components that are not critical for the initial render
const DynamicThemeSettings = dynamic(() => import('../components/settings').then((mod) => mod.ThemeSettings), { ssr: false });
const DynamicMotionLazyContainer = dynamic(() => import('../components/animate').then((mod) => mod.MotionLazyContainer), { ssr: false });
const DynamicSnackbarProvider = dynamic(() => import('../components/snackbar').then((mod) => mod.SnackbarProvider), { ssr: false });
const DynamicGlobalSnackbar = dynamic(() => import('@/components/snackbar/GlobalSnackbar'), { ssr: false });
const DynamicProgressBar = dynamic(() => import('../components/progress-bar').then((mod) => mod.default), { ssr: false });
const DynamicMousePositionProvider = dynamic(
  () => import('@/components/animate/MousePositionContext').then((mod) => mod.MousePositionProvider),
  { ssr: false }
);
const DynamicApiProvider = dynamic(() => import('@/providers/ApiProvider').then((mod) => mod.ApiProvider), { ssr: false });
const DynamicAuthProvider = dynamic(() => import('@/providers/AuthProvider').then((mod) => mod.AuthProvider), { ssr: false });
const DynamicLoadingBackdropProvider = dynamic(() => import('@/utils/LoadingBackdropContext').then((mod) => mod.LoadingBackdropProvider), {
  ssr: false,
});
// const DynamicSupportBeaconScript = dynamic(() => import('@/components/support/BeaconScript'), { ssr: false });
const DynamicFullscreenInfoMessage = dynamic(() => import('@/components/fullscreenInfoMessage/FullscreenInfoMessage'), { ssr: false });
const DynamicFullscreenConfirmMessage = dynamic(() => import('@/components/fullscreenInfoMessage/FullscreenConfirmMessage'), {
  ssr: false,
});
const DynamicFullscreenRestartMessage = dynamic(() => import('@/components/fullscreenInfoMessage/FullScreenRestartMessage'), {
  ssr: false,
});

const clientSideEmotionCache = createEmotionCache();

export default function MyApp(props: { Component: any; pageProps: any; emotionCache?: EmotionCache | undefined }) {
  const { Component, pageProps, emotionCache = clientSideEmotionCache } = props;
  const [i18nInitialized, setI18nInitialized] = useState(false);

  const getLayout = Component.getLayout ?? ((page: any) => page);
  const [safeAreaInsetTop, setSafeAreaInsetTop] = useAtom(safeAreaInsetTopAtom);
  const [safeAreaInsetBottom, setSafeAreaInsetBottom] = useAtom(safeAreaInsetBottomAtom);

  useEffect(() => {
    if (Capacitor.getPlatform() === 'ios') {
      const updateSafeAreaInsets = () => {
        SafeArea.getSafeAreaInsets().then(({ insets }) => {
          setSafeAreaInsetTop(insets.top);
          setSafeAreaInsetBottom(insets.bottom);
        });
      };

      updateSafeAreaInsets(); // Initial call

      window.addEventListener('resize', updateSafeAreaInsets);
      document.addEventListener('visibilitychange', () => {
        if (document.visibilityState === 'visible') {
          updateSafeAreaInsets();
        }
      });

      return () => {
        window.removeEventListener('resize', updateSafeAreaInsets);
        document.removeEventListener('visibilitychange', updateSafeAreaInsets);
      };
    }
  }, []);

  useEffect(() => {
    initializeLanguage();

    i18n
      .init()
      .then(() => {
        setI18nInitialized(true);
      })
      .catch((error) => {
        console.error('i18n initialization failed:', error);
      });
  }, []);

  const [isOnline, setIsOnline] = useState(false);

  useEffect(() => {
    const checkNetworkStatus = async () => {
      const status = await Network.getStatus();
      setIsOnline(status.connectionType !== 'none');
    };

    const handleNetworkChange = (status: any) => {
      setIsOnline(status.connectionType !== 'none');
    };

    checkNetworkStatus();
    Network.addListener('networkStatusChange', handleNetworkChange);

    return () => {
      Network.removeAllListeners();
    };
  }, []);

  if (!i18nInitialized) {
    return <div>Loading...</div>; // Or any loading indicator
  }

  return (
    <CacheProvider value={emotionCache}>
      <ReduxProvider store={store}>
        <DynamicApiProvider>
          <DynamicAuthProvider>
            <SettingsProvider>
              <ThemeProvider>
                <DynamicThemeSettings>
                  <DynamicMotionLazyContainer>
                    <DynamicSnackbarProvider>
                      <BaseTracker ids={['AW-1031623608', 'G-30WZ6JG0CV']} />
                      <DynamicGlobalSnackbar />
                      <DynamicProgressBar />
                      <DynamicLoadingBackdropProvider>
                        <DynamicMousePositionProvider>{getLayout(<Component {...pageProps} />)}</DynamicMousePositionProvider>
                        {/* <MaintenanceMessage /> */}
                        {!isOnline && <NoInternetConnection />}
                      </DynamicLoadingBackdropProvider>
                      <DynamicFullscreenInfoMessage />
                      <DynamicFullscreenConfirmMessage />
                      <DynamicFullscreenRestartMessage />
                      {/* <DynamicSupportBeaconScript /> */}
                    </DynamicSnackbarProvider>
                  </DynamicMotionLazyContainer>
                </DynamicThemeSettings>
              </ThemeProvider>
            </SettingsProvider>
          </DynamicAuthProvider>
        </DynamicApiProvider>
      </ReduxProvider>
    </CacheProvider>
  );
}
