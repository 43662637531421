import AppConfig from '@/config/config';
import { PATH_AUTH, PATH_DASHBOARD } from '@/routes/paths';
import { AuthTypes, UserTypes } from '@api-types';
import { atom, useAtom, useSetAtom } from 'jotai';
import { useRouter } from 'next/router';
import { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { useApi } from './ApiProvider';
import { jwtDecode } from 'jwt-decode';
import { Capacitor } from '@capacitor/core';
import { SavePassword } from 'capacitor-ios-autofill-save-password';
import { resetGlobalAtoms } from '@/hooks/usePictureSet';
import { SecureStorage } from '@aparajita/capacitor-secure-storage';
import uuidv4 from '@/utils/uuidv4';
import { SignInWithAppleOptions } from '@capacitor-community/apple-sign-in';
import { Browser } from '@capacitor/browser';
import { userDownloadsAtom } from '@/src/atoms/useApp.states';

export const userAtom = atom<UserTypes.UserViewModel | null>(null);
export const tokenAtom = atom<string | null>(null);
export const appleLoginTokenAtom = atom<string | null>(null);

export type AuthContext = {
  user: UserTypes.UserViewModel | null;
  token: string | null;
  isAuthenticating: boolean;
  initUserAuth: () => void;
  loginUsingCredentials: (credentials: AuthTypes.LoginRequest) => Promise<void>;
  logout: () => void;
  loginUsingGoogle: () => void;
  loginUsingGitHub: () => void;
  loginUsingApple: () => void;
  loginAsGuest: () => void;
  saveAuthData: (
    user: UserTypes.UserViewModel,
    accessToken: string,
    refreshToken: string,
    accessTokenExpiresAt: Date,
    refreshTokenExpiresAt: Date
  ) => void;
};

const AuthContext = createContext<AuthContext>({} as Partial<AuthContext> as AuthContext);

export const useAuth = () => useContext(AuthContext);
export const isAuthenticatingAtom = atom<boolean>(false);
export const firstRedirectAfterLoginAtom = atom(false);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const { userApi, authApi } = useApi();
  const router = useRouter();
  const [user, setUser] = useAtom(userAtom);
  const [isAuthenticating, setIsAuthenticating] = useAtom(isAuthenticatingAtom);
  const [accessToken, setToken] = useAtom(tokenAtom);
  const [accessTokenExpireAt, setAccessTokenExpireAt] = useState<Date | null>(null);
  const tokenIsExpired = accessTokenExpireAt && accessTokenExpireAt < new Date();
  const [firstRedirectAfterLogin, setFirstRedirectAfterLogin] = useAtom(firstRedirectAfterLoginAtom);
  const setUserDownloads = useSetAtom(userDownloadsAtom);

  useEffect(() => {
    // Check if the current route is not the login-success page before initializing user auth
    if (router.pathname !== '/auth/login-success') {
      initUserAuth();
    }
  }, []);

  useEffect(() => {
    const checkAuthStatus = () => {
      const accessToken = localStorage.getItem('accessToken');
      const refreshToken = localStorage.getItem('refreshToken');
      const accessTokenExpiresAt = localStorage.getItem('accessTokenExpiresAt');
      const refreshTokenExpiresAt = localStorage.getItem('refreshTokenExpiresAt');

      if (!accessToken || !refreshToken || !accessTokenExpiresAt || !refreshTokenExpiresAt) {
        cleanAuthDataAndRedirect();
      } else if (new Date(accessTokenExpiresAt) < new Date()) {
        handleStoredAccessCredentials(accessToken);
      }
    };

    // Check on visibility change
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        checkAuthStatus();
      }
    };

    // Check on route change (including shallow routing)
    const handleRouteChange = () => {
      checkAuthStatus();
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [tokenIsExpired, router]);

  const GUEST_USER_ID_KEY = 'guestUserId';

  const generateGuestUserId = () => {
    return uuidv4(); // Generate a UUID
  };

  const saveGuestUserIdToKeychain = async (guestUserId: string) => {
    try {
      // Enable iCloud synchronization
      await SecureStorage.setSynchronize(true);

      // Store the guestUserId in the Keychain
      await SecureStorage.set(GUEST_USER_ID_KEY, guestUserId, false, true);
    } catch (error) {
      console.error('Error storing guest user ID in Keychain:', error);
    }
  };

  const getGuestUserIdFromKeychain = async () => {
    try {
      const result = await SecureStorage.get(GUEST_USER_ID_KEY, false, true);
      return result as string | null;
    } catch (error) {
      console.error('Error retrieving guest user ID from Keychain:', error);
      // Handle the error appropriately (e.g., fallback to generating a new guest user ID)
      return null;
    }
  };

  const saveAuthData = (
    user: UserTypes.UserViewModel,
    accessToken: string,
    refreshToken: string,
    accessTokenExpiresAt: Date,
    refreshTokenExpiresAt: Date
  ) => {
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('refreshToken', refreshToken);
    localStorage.setItem('refreshTokenExpiresAt', refreshTokenExpiresAt.toISOString());
    localStorage.setItem('accessTokenExpiresAt', accessTokenExpiresAt.toISOString());
    setToken(accessToken);
    setAccessTokenExpireAt(accessTokenExpiresAt);
    setUser(user);
    setUserDownloads(user.balance.downloads);
    // console.log('user', user);
  };

  const cleanAuthDataAndRedirect = async () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('accessTokenExpiresAt');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('refreshTokenExpiresAt');
    setUser(null);
    setToken(null);
    setAccessTokenExpireAt(null);
    // redirect to login page only if the user is in the dashboard
    if (router.pathname.startsWith(PATH_DASHBOARD.root)) {
      router.push(PATH_AUTH.login, undefined, { shallow: true });
    }
  };

  const initUserAuth = async () => {
    if (isAuthenticating) {
      return;
    }
    setIsAuthenticating(true);

    try {
      const accessToken = localStorage.getItem('accessToken');
      if (accessToken) {
        await handleStoredAccessCredentials(accessToken);
      } else {
        setTimeout(cleanAuthDataAndRedirect, 1000); // Retry after 1 second
      }
    } catch (error) {
      setTimeout(cleanAuthDataAndRedirect, 1000); // Retry after 1 second
    } finally {
      setIsAuthenticating(false);
    }
  };

  const loginAsGuest = async () => {
    setIsAuthenticating(true);
    try {
      let guestUserId = await getGuestUserIdFromKeychain();

      if (!guestUserId) {
        guestUserId = generateGuestUserId();
        await saveGuestUserIdToKeychain(guestUserId);
      }

      const guestUser = await authApi.loginAsGuest(guestUserId);
      saveAuthData(
        guestUser,
        guestUser.accessToken,
        guestUser.refreshToken,
        new Date(guestUser.accessTokenExpiresAt),
        new Date(guestUser.refreshTokenExpiresAt)
      );
      resetGlobalAtoms();
      router.push(PATH_DASHBOARD.general.app, undefined, { shallow: true });
    } catch (error) {
      console.error('Guest login failed:', error);
      // Display error message to the user
    } finally {
      setIsAuthenticating(false);
    }
  };

  const loginUsingCredentials = async (credentials: AuthTypes.LoginRequest) => {
    setFirstRedirectAfterLogin(true);
    setIsAuthenticating(true);
    try {
      const user = await authApi.loginWithCredentials(credentials);
      saveAuthData(user, user.accessToken, user.refreshToken, new Date(user.accessTokenExpiresAt), new Date(user.refreshTokenExpiresAt));
      resetGlobalAtoms();

      // Prompt to save credentials on iOS
      if (Capacitor.getPlatform() === 'ios') {
        await SavePassword.promptDialog({
          username: credentials.email,
          password: credentials.password,
        });
      }
      resetGlobalAtoms();
      router.push(PATH_DASHBOARD.general.app, undefined, { shallow: true });
    } finally {
      setIsAuthenticating(false);
    }
  };

  const handleStoredAccessCredentials = async (accessToken: string) => {
    const accessTokenExpiresAt = new Date(localStorage.getItem('accessTokenExpiresAt')!);
    const refreshToken = localStorage.getItem('refreshToken');
    const refreshTokenExpiresAt = new Date(localStorage.getItem('refreshTokenExpiresAt')!);

    if (accessTokenExpiresAt > new Date()) {
      // token is valid, nothing to do
      const userData = await userApi.fetchUserData(accessToken);
      saveAuthData(userData, accessToken, refreshToken!, accessTokenExpiresAt, refreshTokenExpiresAt);
    } else if (refreshToken && refreshTokenExpiresAt > new Date()) {
      // token is expired, try to refresh it
      const decoded = jwtDecode<AuthTypes.JwtUser>(accessToken);
      const authenticatedUser = await authApi.refreshAccessToken({ userId: decoded.id, refreshToken });
      saveAuthData(
        authenticatedUser,
        authenticatedUser.accessToken,
        authenticatedUser.refreshToken,
        new Date(authenticatedUser.accessTokenExpiresAt),
        new Date(authenticatedUser.refreshTokenExpiresAt)
      );
    } else {
      // Both tokens are expired, clear auth data and redirect to login
      await cleanAuthDataAndRedirect();
    }
  };

  const loginUsingGoogle = async () => {
    setFirstRedirectAfterLogin(true);
    resetGlobalAtoms();
    const guestUserId = await getGuestUserIdFromKeychain();
    const url = new URL(`${AppConfig.apiDomain}/auth/google`);
    if (guestUserId) {
      url.searchParams.append('guestUserId', guestUserId);
    }

    if (Capacitor.getPlatform() !== 'android') {
      window.open(url.toString(), '_self');
    } else {
      const redirectUrl = `${AppConfig.appDomain}/auth/google/callback`;
      url.searchParams.append('redirect_uri', redirectUrl);
      url.searchParams.append('platform', 'native');
      await Browser.open({ url: url.toString() });

      Browser.addListener('browserFinished', async () => {
        // Handle the logic to fetch the auth data after the browser is closed
        const accessToken = localStorage.getItem('accessToken');
        if (accessToken) {
          await handleStoredAccessCredentials(accessToken);
        } else {
          // Handle the case where the access token is not found
          console.error('Access token not found after browser finished');
        }
      });
    }
  };

  const loginUsingGitHub = async () => {
    setFirstRedirectAfterLogin(true);
    resetGlobalAtoms();
    const guestUserId = await getGuestUserIdFromKeychain();
    const url = new URL(`${AppConfig.apiDomain}/auth/github`);
    if (guestUserId) {
      url.searchParams.append('guestUserId', guestUserId);
    }
    window.open(url.toString(), '_self');
  };

  let loginUsingApple: () => void;

  if (Capacitor.getPlatform() !== 'ios') {
    loginUsingApple = async () => {
      setFirstRedirectAfterLogin(true);
      resetGlobalAtoms();
      const guestUserId = await getGuestUserIdFromKeychain();
      const url = new URL(`${AppConfig.apiDomain}/auth/apple`);
      if (guestUserId) {
        url.searchParams.append('guestUserId', guestUserId);
      }
      window.open(url.toString(), '_self');
    };
  } else {
    loginUsingApple = async () => {
      setIsAuthenticating(true);
      setFirstRedirectAfterLogin(true);
      try {
        const { SignInWithApple } = await import('@capacitor-community/apple-sign-in');

        let options: SignInWithAppleOptions = {
          clientId: 'studio.myportrait.SignIn',
          redirectURI: `${AppConfig.appDomain}/${PATH_AUTH.loginSuccess}`,
          scopes: 'email name',
          state: '12345',
          nonce: 'nonce',
        };
        const result = await SignInWithApple.authorize(options);

        const { identityToken, authorizationCode, givenName, familyName } = result.response;
        const guestUserId = await getGuestUserIdFromKeychain();
        if (identityToken && authorizationCode) {
          const response = await fetch(`${AppConfig.apiDomain}/auth/apple/callback`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              identityToken,
              authorizationCode,
              guestUserId, // Include guestUserId if it exists
              familyName,
              givenName,
            }),
          });

          if (!response.ok) {
            throw new Error('Apple Sign-In failed');
          }

          const data = await response.json();
          saveAuthData(
            data.user,
            data.accessToken,
            data.refreshToken,
            new Date(data.accessTokenExpiresAt),
            new Date(data.refreshTokenExpiresAt)
          );
          resetGlobalAtoms();
          router.push(PATH_DASHBOARD.general.app, undefined, { shallow: true });
        } else {
          throw new Error('Apple Sign-In failed');
        }
      } catch (error) {
        console.error('Apple Sign-In error:', error);
      } finally {
        setIsAuthenticating(false);
      }
    };
  }

  const logout = async () => {
    try {
      await authApi.logout();
      resetGlobalAtoms();
      setUser(null);
    } finally {
      await cleanAuthDataAndRedirect();
    }
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        token: accessToken,
        isAuthenticating,
        saveAuthData,
        logout,
        loginUsingCredentials,
        loginUsingGoogle,
        loginUsingGitHub,
        loginUsingApple,
        loginAsGuest,
        initUserAuth,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
