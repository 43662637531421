import { useRouter } from 'next/router';
import { useMemo } from 'react';

export function useRouterQuery() {
  const { query, pathname } = useRouter();

  const values = useMemo(() => {
    const portraitSetId = query.portraitSetId ? Number(query.portraitSetId) : null;
    const pictureSetId = query.pictureSetId ? Number(query.pictureSetId) : null;
    const switchToPictureSetId = query.switchToPictureSetId ? Number(query.switchToPictureSetId) : null;
    const page = query.page ? Number(query.page) : null;

    return { portraitSetId, pictureSetId, page, pathname, switchToPictureSetId };
  }, [query.portraitSetId, query.pictureSetId, query.page, pathname, query.switchToPictureSetId]);

  return values;
}
