import { CheckboxIcon, CheckboxCheckedIcon, CheckboxIndeterminateIcon } from './CustomIcons';

// ----------------------------------------------------------------------
type OwnerState = {
  size: 'small' | 'medium';
};
export default function Checkbox(theme: any) {
  return {
    MuiCheckbox: {
      defaultProps: {
        icon: <CheckboxIcon component={'svg'} />,
        checkedIcon: <CheckboxCheckedIcon component={'svg'} />,
        indeterminateIcon: <CheckboxIndeterminateIcon component={'svg'} />,
      },

      styleOverrides: {
        root: ({ ownerState }: { ownerState: OwnerState }) => ({
          padding: theme.spacing(1),
          ...(ownerState.size === 'small' && {
            '& svg': { width: 20, height: 20 },
          }),
          ...(ownerState.size === 'medium' && {
            '& svg': { width: 24, height: 24 },
          }),
        }),
      },
    },
  };
}
