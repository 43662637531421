import { AuthTypes, UserTypes } from '@api-types';
import { BaseApi, TranslationMapping } from './_baseApi';

export class UserApi extends BaseApi {
  constructor(setSnackbarQueue: any, translate: (text: any, options?: any) => string, token: string | null) {
    super(setSnackbarQueue, translate, token);
  }

  public fetchUserData = async (token: string): Promise<UserTypes.UserViewModel> => {
    const response = await fetch(`${this.baseURL}/user`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.ok) return await response.json();
    throw this.handleError(response);
  };

  public activate = async (token: string): Promise<UserTypes.AuthenticatedUserViewModel> => {
    const codeMappings: TranslationMapping = {
      403: 'userRegistration.errors.alreadyActivated',
      404: 'userRegistration.errors.tokenNotFound',
      default: 'userRegistration.errors.activationFailed',
    };

    return await this.post<UserTypes.AuthenticatedUserViewModel>('/user/activate', { token: token }, codeMappings);
  };

  public register = async (data: AuthTypes.UserRegistrationRequest): Promise<void> => {
    const codeMappings: TranslationMapping = {
      403: 'userRegistration.errors.emailExists',
      default: 'userRegistration.errors.registrationFailed',
    };

    await this.postVoid('/user/register', data, codeMappings);
  };
}
