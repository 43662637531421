import UserExt from '../../infrastructure/prisma/ext/user.ext';
import { z } from 'zod';

/**
 * Represents the required fields for user registration.
 */
export type UserRegistrationRequest = Pick<
  z.infer<typeof UserExt.UserBaseSchema>,
  'firstName' | 'lastName' | 'email' | 'birthday' | 'password' | 'language'
>;

/**
 * Represents the validation pattern for the password.
 * Password must contain one digit from 1 to 9, one lowercase letter, one uppercase letter, one special character, no space, and it must be 8-64 characters long.
 */
export const PassValidationPattern = /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[^\w\d\s:])([^\s]){8,32}$/;
