import { RadioIcon, RadioCheckedIcon } from './CustomIcons';

type OwnerState = {
  size: 'small' | 'medium';
};

export default function Radio(theme: any) {
  return {
    MuiRadio: {
      defaultProps: {
        icon: <RadioIcon component={'svg'} />,
        checkedIcon: <RadioCheckedIcon component={'svg'} />,
      },

      styleOverrides: {
        root: ({ ownerState }: { ownerState: OwnerState }) => ({
          padding: theme.spacing(1),
          ...(ownerState.size === 'small' && {
            '& svg': { width: 20, height: 20 },
          }),
          ...(ownerState.size === 'medium' && {
            '& svg': { width: 24, height: 24 },
          }),
        }),
      },
    },
  };
}
