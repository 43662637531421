import { createSlice } from '@reduxjs/toolkit';
// utils
interface DataCacheState {
  cachedData: { [key: string]: any };
  isLoading: boolean;
  error: any;
}

interface PayloadAction {
  payload: {
    query: string;
    data: any;
  };
}

const dataCacheSlice = createSlice({
  name: 'dataCache',
  initialState: {
    cachedData: {},
    isLoading: false,
    error: null,
  } as DataCacheState,
  reducers: {
    setCachedData: (state: DataCacheState, action: PayloadAction) => {
      const { query, data } = action.payload;
      state.cachedData[query] = data;
    },
    setNewData: (state: DataCacheState, action: PayloadAction) => {
      const { query, data } = action.payload;
      state.cachedData[query] = data;
    },
    clearCacheForQuery: (state: DataCacheState, action: PayloadAction) => {
      const { query } = action.payload;
      if (state.cachedData.hasOwnProperty(query)) {
        delete state.cachedData[query];
      }
    },
  },
});

export const { setCachedData, setNewData, clearCacheForQuery } = dataCacheSlice.actions;
export default dataCacheSlice.reducer;
