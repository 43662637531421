import { configureStore } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { persistReducer } from 'redux-persist';
import rootReducer from './rootReducer';
import { ionicStorage } from './ionicStorage';

// Redux Persist Configuration
const persistConfig = {
  key: 'root',
  storage: ionicStorage,
  // Add any other redux-persist configuration options here
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// Configure Store
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }),
});

const { dispatch } = store;

export { store, dispatch, useSelector, useDispatch };
