import { getDefaultStore, useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useEffect, useRef, useState } from 'react';
import { fetchDataFromBackend } from '@/src/communication/socket/dataRequest';
import { Gender, PictureSetStatus, PictureTypes, SocketEmits } from '@api-types';
import {
  inTrainingAtom,
  readyPictureSetsAtom,
  reloadPictureSetAtom,
  selectedPictureSetAtom,
  inTrainingPictureSetsAtom,
  selectedPreviewGenderAtom,
  selectedFoundationPictureAtom,
  foundationPicturesAtom,
  selectedPictureSetDataIsLoadedAtom,
  isSelectedPictureSetLoadedAtom,
} from '@/src/atoms/upload.states';
import { RESET } from 'jotai/utils';
import { useRouterQuery } from './useRouterQuery';
import { useRouter } from 'next/router';
import {
  activeRatioAtom,
  appearanceAgeAtom,
  appearanceEyeColorAtom,
  appearanceHairColorAtom,
  appearancehaircutAtom,
  appearancehaircutNameAtom,
  appearanceStyleAtom,
  customInputAtom,
  detailEnhancerAtom,
  editModeAtom,
  editModeChatAtom,
  renderModeAtom,
  selectedEyeColorAtom,
  selectedHairColorAtom,
  similarityStrengthAtom,
} from '@/sections/dashboard/menu/Content.hooks';
import { socketAtom } from '@/providers/WebSocketProvider';
import { appActiveSectionModeAtom, pictureSetsDataIsLoadedAtom, pictureSetsDataAtom } from '../atoms/useApp.states';
import {
  currentConversationIdAtom,
  conversationsIsLoadedAtom,
  localMessagesAtom,
  messagesAtom,
  introModeAtom,
  conversationsAtom,
} from '@/sections/dashboard/chatInterface/Chat.states';

const findHighestIdPictureSet = (data: PictureTypes.PictureSetViewModel[]) =>
  data.reduce((prev, current) => {
    if (!current || !prev) return current || prev; // Handle null 'current' and 'prev'
    return prev.id > current.id ? prev : current;
  });

const updateSelectedSet = (
  set: PictureTypes.PictureSetViewModel | null,
  setSelectedPictureSet: (set: PictureTypes.PictureSetViewModel | null) => void,
  setInTraining: (training: boolean) => void,
  router: ReturnType<typeof useRouter>,
  setSelectedPreviewGender: (gender: Gender | null) => void
) => {
  if (!set) return; // Handle null 'set'
  setSelectedPictureSet(set);
  setSelectedPreviewGender(set.gender);
  if (set.status === PictureSetStatus.startTraining) {
    setInTraining(true);
  } else {
    setInTraining(false);
  }

  // Remove switchToPictureSetId from the URL and perform a shallow reload
  const { switchToPictureSetId, ...restQuery } = router.query;
  router.replace(
    {
      pathname: router.pathname,
      query: restQuery,
    },
    undefined,
    { shallow: true }
  );
};

export const usePictureSet = () => {
  const reloadPictureSet = useAtomValue(reloadPictureSetAtom);
  const [data, setData] = useState<PictureTypes.PictureSetViewModel[] | null>(null);
  const [readyPictureSets, setReadyPictureSets] = useAtom(readyPictureSetsAtom);
  const [selectedPictureSet, setSelectedPictureSet] = useAtom(selectedPictureSetAtom);
  const { switchToPictureSetId } = useRouterQuery();
  const setInTraining = useSetAtom(inTrainingAtom);
  const setInTrainingPictureSets = useSetAtom(inTrainingPictureSetsAtom);
  const router = useRouter();
  const { pictureSetId } = useRouterQuery();
  const setSelectedPreviewGender = useSetAtom(selectedPreviewGenderAtom);
  const [selectedPictureSetDataIsLoaded, setSelectedPictureSetDataIsLoaded] = useAtom(selectedPictureSetDataIsLoadedAtom);
  const setIsSelectedPictureSetLoaded = useSetAtom(isSelectedPictureSetLoadedAtom);
  const socket = useAtomValue(socketAtom);

  // useStateDataRequest(
  //   SocketEmits.GetPictureSetsDataWithIds,
  //   switchToPictureSetId ? [switchToPictureSetId] : null,
  //   setData,
  //   reloadPictureSet
  // );

  useEffect(() => {
    if (socket) {
      const fetchData = async () => {
        const tmp = await fetchDataFromBackend<PictureTypes.PictureSetViewModel[] | null>(
          socket,
          SocketEmits.GetPictureSetsDataWithIds,
          switchToPictureSetId ? [switchToPictureSetId] : null
        );

        setSelectedPictureSetDataIsLoaded(true);

        setData(tmp);
      };
      fetchData();
    }
  }, [socket, reloadPictureSet, switchToPictureSetId]);

  useEffect(() => {
    if (data && data.length > 0) {
      const readySets = data.filter((pictureSet) => pictureSet?.status === PictureSetStatus.ready);
      const inTrainingSets = data.filter((pictureSet) => pictureSet?.status === PictureSetStatus.startTraining);
      setReadyPictureSets(readySets);
      setInTrainingPictureSets(inTrainingSets);

      if (readySets.length === 0) {
        setSelectedPictureSet(null);
        setSelectedPreviewGender(null);
      } else if (!selectedPictureSet || !readySets.some((set) => set?.id === selectedPictureSet.id)) {
        const highestIdPictureSet = findHighestIdPictureSet(readySets);
        if (highestIdPictureSet && !selectedPictureSet) {
          setSelectedPictureSet(highestIdPictureSet);
          setSelectedPreviewGender(highestIdPictureSet.gender);
        }
      }

      // const pictureSet = inTrainingPictureSets.find((set) => set?.id === pictureSetId) || inTrainingPictureSets[0];

      if (inTrainingSets.length > 0 && !pictureSetId) {
        setInTraining(true);
      }

      if (switchToPictureSetId) {
        const selectedSet = data.find((ps) => ps?.id === switchToPictureSetId);
        if (selectedSet) {
          updateSelectedSet(selectedSet, setSelectedPictureSet, setInTraining, router, setSelectedPreviewGender);
        }
      }
    }
    if (selectedPictureSetDataIsLoaded) {
      setIsSelectedPictureSetLoaded(true);
    }
  }, [switchToPictureSetId, data, selectedPictureSet, pictureSetId]);

  const prevSelectedPictureSetRef = useRef<PictureTypes.PictureSetViewModel | null>(null);

  useEffect(() => {
    if (readyPictureSets.length > 0 && (!selectedPictureSet || !readyPictureSets.some((set) => set?.id === selectedPictureSet.id))) {
      const newSelectedSet = readyPictureSets[0] ?? null;
      if (newSelectedSet !== prevSelectedPictureSetRef.current) {
        prevSelectedPictureSetRef.current = newSelectedSet;
        setSelectedPictureSet(newSelectedSet);
      }
    }
  }, [readyPictureSets, selectedPictureSet]);

  return {
    readyPictureSets,
    selectedPictureSet,
    setSelectedPictureSet,
  };
};

export const resetGlobalAtoms = () => {
  const store = getDefaultStore();
  store.set(selectedPictureSetAtom, RESET);
  store.set(inTrainingAtom, RESET);
  store.set(readyPictureSetsAtom, RESET);
  store.set(inTrainingPictureSetsAtom, RESET);
  store.set(selectedFoundationPictureAtom, RESET);
  store.set(foundationPicturesAtom, RESET);
  store.set(selectedPreviewGenderAtom, RESET);
  store.set(editModeAtom, RESET);

  // Reset only atomWithStorage atoms from Content.hooks.ts
  store.set(appearancehaircutAtom, RESET);
  store.set(appearancehaircutNameAtom, RESET);
  store.set(appearanceHairColorAtom, RESET);
  store.set(appearanceEyeColorAtom, RESET);
  store.set(appearanceStyleAtom, RESET);
  store.set(appearanceAgeAtom, RESET);
  store.set(selectedHairColorAtom, RESET);
  store.set(selectedEyeColorAtom, RESET);
  store.set(customInputAtom, RESET);
  store.set(detailEnhancerAtom, RESET);
  store.set(similarityStrengthAtom, RESET);
  store.set(activeRatioAtom, RESET);
  store.set(renderModeAtom, RESET);
  store.set(editModeAtom, RESET);
  store.set(appActiveSectionModeAtom, RESET);
  store.set(pictureSetsDataAtom, null);
  store.set(pictureSetsDataIsLoadedAtom, false);
  store.set(messagesAtom, RESET);
  store.set(localMessagesAtom, RESET);
  store.set(currentConversationIdAtom, null);
  store.set(conversationsIsLoadedAtom, false);
  store.set(introModeAtom, false);
  store.set(conversationsAtom, []);
  store.set(socketAtom, null);
  store.set(editModeChatAtom, RESET);
};
