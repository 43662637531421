import { Storage } from '@ionic/storage';

const storage = new Storage();
let isStorageReady = false;

if (typeof window !== 'undefined') {
  storage
    .create()
    .then(() => {
      isStorageReady = true;
    })
    .catch((error) => {
      console.error('Failed to initialize storage:', error);
    });
}

export const ionicStorage = {
  getItem: async (key: string) => {
    if (isStorageReady) {
      return await storage.get(key);
    }
    // console.warn('Storage not ready. Cannot get item.');
    return null;
  },
  setItem: async (key: string, value: any) => {
    if (isStorageReady) {
      await storage.set(key, value);
      return;
    }
    // console.warn('Storage not ready. Cannot set item.');
  },
  removeItem: async (key: string) => {
    if (isStorageReady) {
      await storage.remove(key);
      return;
    }
    // console.warn('Storage not ready. Cannot remove item.');
  },
  clear: async () => {
    if (isStorageReady) {
      await storage.clear();
      return;
    }
    // console.warn('Storage not ready. Cannot clear.');
  },
};
