import AppConfig from '@/config/config';
import { SocketEvents } from '@api-types';
import { ReactNode, createContext, useContext, useEffect, useRef } from 'react';
import io, { Socket } from 'socket.io-client';
import { useAuth } from './AuthProvider';
import { PATH_DASHBOARD } from '@/routes/paths';
import { useRouter } from 'next/router';
import { atom, useAtom } from 'jotai';
import { updateMessageAtom } from '@/components/fullscreenInfoMessage/FullScreenRestartMessage';

export type WebSocketContext = {
  isConnecting: boolean;
  isConnected: boolean;
  socket: Socket | null;
};

export const socketAtom = atom<Socket | null>(null);
export const isConnectingAtom = atom<boolean>(false);
export const isConnectedAtom = atom<boolean>(false);

const WebSocketContext = createContext<WebSocketContext>({} as WebSocketContext);
export const useWebSocket = () => useContext(WebSocketContext);

export const WebSocketProvider = ({ children }: { children: ReactNode }) => {
  const { user, token, logout, initUserAuth, isAuthenticating } = useAuth();
  const [socket, setSocket] = useAtom(socketAtom);
  const [isConnecting, setIsConnecting] = useAtom(isConnectingAtom);
  const [isConnected, setIsConnected] = useAtom(isConnectedAtom);
  const router = useRouter();
  const socketRef = useRef<Socket | null>(null);
  const [, setUpdateMessage] = useAtom(updateMessageAtom);

  useEffect(() => {
    if (!socket) return;
    socket.on(SocketEvents.ServerRestarted, (data: { message: string | ReactNode }) => {
      if (data.message !== AppConfig.version && data.message !== 'dev' && data.message !== 'development') {
        setUpdateMessage((prev) => ({ ...prev, open: true }));
      }
    });
  }, [socket]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        const currentSocket = socketRef.current;

        if (!currentSocket?.connected) {
          console.debug('[WebSocket] Tab became visible, reconnecting existing socket...');
          currentSocket?.connect();
        }
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  const initializeSocket = (token: string) => {
    if (socketRef.current?.connected) {
      console.debug('[WebSocket] Socket already connected, skipping initialization');
      return;
    }

    setIsConnecting(true);
    const newSocket = io(`${AppConfig.apiDomain}`, {
      extraHeaders: {
        authorization: `Bearer ${token}`,
      },
      withCredentials: true,
      reconnection: true,
      reconnectionAttempts: Infinity,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 30000,
      timeout: 20000,
      transports: ['polling', 'websocket'],
      autoConnect: true,
    });

    newSocket.on(SocketEvents.Connect, () => {
      setSocket(newSocket);
      setIsConnecting(false);
    });

    newSocket.on(SocketEvents.ConnectError, (err: any) => {
      setIsConnecting(false);
      if (err?.context?.status === 401) {
        disposeSocket(newSocket);
        setTimeout(() => {
          if (!isAuthenticating) initUserAuth();
        }, 5000);
        return;
      }
    });

    newSocket.on(SocketEvents.Disconnect, () => {
      setIsConnected(false); // Set isConnected to false on disconnect
      // ... (add any additional disconnect handling) ...
    });

    newSocket.on(SocketEvents.Logout, async () => {
      setSocket(null);
      logout();
    });

    socketRef.current = newSocket;
  };

  const disposeSocket = (socketToDispose: Socket | null) => {
    if (!socketToDispose) return;
    Object.values(SocketEvents).forEach((element) => {
      socketToDispose?.off(element);
    });
    socketToDispose?.close();
    setSocket(null);
  };

  useEffect(() => {
    if (isAuthenticating) {
      return;
    }

    if (router.pathname.startsWith(PATH_DASHBOARD.root)) {
      if (user && token) {
        initializeSocket(token);
      } else {
        disposeSocket(socketRef.current);
      }
    } else {
      initializeSocket('guest');
    }

    return () => {
      disposeSocket(socketRef.current);
    };
  }, [user, token, isAuthenticating]);

  return <WebSocketContext.Provider value={{ socket, isConnecting, isConnected }}>{children}</WebSocketContext.Provider>;
};
