import { PictureTypes } from '@api-types';
import { atom } from 'jotai';
import { atomWithReset, atomWithStorage } from 'jotai/utils';
import { EditMode } from '../menu/Content.hooks';

export type Message = {
  id: number;
  conversationId: number;
  role: 'user' | 'ai';
  content: string;
  timestamp: string;
  type?: 'message' | 'prompt';
  completed?: boolean;
  pictures?: PictureTypes.PictureViewModel[];
};

export type Conversation = {
  id: number;
  userId: number;
  title: string;
  startedAt: string;
  updatedAt: string;
  status: string;
  messages: Message[];
  pictureSetId: number;
  editMode: EditMode;
};

export const showVerticalChatMenuAtom = atom(false);
export const currentConversationIdAtom = atom<number | null>(null);
export const messagesAtom = atomWithReset<Message[]>([]);
export const hasMoreMessagesAtom = atom(true);
export const pageAtom = atom(1);
export const isLoadingAtom = atom(false);
export const isLoadingMoreMessagesAtom = atom(false);
export const messagesPerPageAtom = atom(20);
export const fireLoadMoreMessagesAtom = atom(false);
export const chatInputAtom = atom('');
export const autoGenerateAtom = atom(false);
export const chatModeAtom = atomWithStorage<boolean>('chatMode', false);
export const chatModeAiPromptAtom = atom<string>('');
export const reloadConversationsAtom = atom(false);
export const isFirstMessageAtom = atom(false);

// Atom to store the list of conversations
export const conversationsAtom = atom<Conversation[]>([]);
export const conversationsIsLoadedAtom = atom(false);
export const introModeAtom = atom(false);
export const localMessagesAtom = atomWithReset<Message[]>([]);

export enum NewConversationMode {
  Welcome = 'welcome',
  NewChat = 'newChat',
  NewUser = 'newUser',
  NewCharacterChat = 'newCharacterChat',
  InitNewChat = 'initNewChat',
  NewArtistChat = 'newArtistChat',
}
export const newConversationTriggerAtom = atom<NewConversationMode | null>(null);

export const lastSelectedCreationModeAtom = atomWithReset<EditMode>(EditMode.Freestyle);
export const newChatModeSelectorAtom = atom(false);

// Derived atom to get the current conversation based on selected ID
export const currentConversationAtom = atom(
  (get) => {
    const currentId = get(currentConversationIdAtom);
    const conversations = get(conversationsAtom);
    return conversations.find((conv) => conv.id === currentId) || null;
  },
  (get, set, newConversationId: number | null) => {
    set(currentConversationIdAtom, newConversationId);
  }
);

export type MessagePictureState = {
  inProgress: PictureTypes.PictureViewModel[];
  completed: PictureTypes.PictureViewModel[];
};

// Combined atom for message pictures
export const messagePicturesAtom = atom<Record<number, MessagePictureState>>({});
