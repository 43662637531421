import { PictureStatus as PrismaPictureStatus } from '@prisma/client';
import { EditMode as PrismaEditMode } from '@prisma/client';
import { PictureGenerationResultReason } from '..';
import { PictureOptionsSchema, PictureSchema } from '../../infrastructure/prisma/ext/picture.ext';
import { z } from 'zod';
import { PhotoEditorImageSchema } from '../../infrastructure/prisma/ext/photoEditorImage.ext';
import { FoundationPicturesViewModel } from './foundationPictures';
import { FoundationPicturesModel } from '../../infrastructure/prisma/ext/foundationPictures.ext';
import { SimpleVideoViewModel } from '@models/video/video';

export type PictureStatus = PrismaPictureStatus;
export type EditMode = PrismaEditMode;
export type PictureOptions = z.infer<typeof PictureOptionsSchema>;
export type PictureModel = z.infer<typeof PictureSchema>;
export type PhotoEditorImageModel = z.infer<typeof PhotoEditorImageSchema>;
export type PictureViewModel = PictureModel & {
  foundationImage?: PictureModel;
  newPictureId: number;
  nude: boolean;
  isPreview?: boolean;
  status?: PictureStatus;
  id?: number;
  videos?: SimpleVideoViewModel[];
};

export type SimplePictureViewModel = {
  id: number;
  pictureUrl: string;
  pictureThumbUrl: string;
};

export type CreateNewPictureInput = {
  userId: number;
  portraitSetsId: number;
  pictureSetsId: number;
  processOptions: PictureOptions;
  jobId: string;
  status: PictureStatus;
};

export type CreateNewPictureInputPhotoEditor = {
  userId: number;
  portraitSetsId: number;
  pictureSetsId: number;
  processOptions: PictureOptions;
  jobId: string;
  status: PictureStatus;
  pictures: PhotoEditorImageModel[];
};

export type PhotoEditorResultViewModel = {
  pictures: PhotoEditorImageModel[]; // Changed from single item to array
  foundationImageId?: number | null;
  category?: string;
  jobs?: ImageGenerationJobViewModel[];
  isPreview?: boolean;
  status?: PictureStatus;
  chatGeneration?: boolean;
  conversationId?: number;
  messageId?: number;
};

export type PicturesResultViewModel = {
  category?: string;
  pictures: PictureViewModel[];
  jobs?: ImageGenerationJobViewModel[];
  isPreview?: boolean;
  status?: PictureStatus;
  chatGeneration?: boolean;
  conversationId?: number;
  messageId?: number;
};

export type FoundationPictureViewModel = FoundationPicturesModel & {
  id: number;
  userId: number;
  portraitSetsId: number;
  pictureSetsId?: number | null;
  foundationImageId?: number | null;
  foundationImage?: FoundationPicturesViewModel | null;
};

export type PictureViewModelPhotoEditor = PhotoEditorImageModel & {
  id: number;
  userId: number;
  portraitSetsId: number;
  pictureSetsId?: number | null;
  foundationImageId?: number | null;
  foundationImage?: FoundationPictureViewModel | null;
  pictureUrl: string;
  pictureThumbUrl: string;
  dateGenerated: Date;
  processOptions?: PictureOptions;
  nude?: boolean | null;
  hasNude?: boolean | null;
  jobId: string;
  status: PictureStatus;
};

export type PicturesResultViewModelPhotoEditor = {
  category?: string;
  pictures: PictureViewModelPhotoEditor[];
  jobs?: ImageGenerationJobViewModel[];
  isPreview?: boolean;
  status?: PictureStatus;
  chatGeneration?: boolean;
  conversationId?: number;
  messageId?: number;
};

export type NewPictureViewModel = Pick<PictureModel, 'id' | 'pictureUrl' | 'pictureThumbUrl' | 'userId' | 'portraitSetsId'> & {
  pictureCount: number;
  newPicturesCount: number;
  picturesToGenerate: number;
  isPreview: boolean;
  status: PictureStatus;
};

export type PictureGenerationRequest = {
  pictureSetId: number | null;
  portraitSetId: number;
  processOptions: PictureOptions;
  noCharge?: boolean;
  picturesToGenerate?: number;
  status: PictureStatus;
  chatGeneration?: boolean;
  conversationId?: number;
  messageId?: number;
};
export type PictureGenerationRequestArtist = {
  processOptions: PictureOptions;
  noCharge?: boolean;
  status: PictureStatus;
  chatGeneration?: boolean;
  conversationId?: number;
  messageId?: number;
};

export type PictureGenerationRequestPhotoEditor = {
  pictureSetId: number;
  portraitSetId: number;
  processOptions: PictureOptions;
  noCharge?: boolean;
  status: PictureStatus;
  foundationImageId: number | null;
  chatGeneration?: boolean;
  conversationId?: number;
  messageId?: number;
};

export type ImageGenerationJobViewModel = {
  jobId?: string;
  userId: number;
  picturesToGenerate: number;
  canceled: boolean;
  reason?: PictureGenerationResultReason;
  estimatedWaitTime?: number;
  pictureSetId: number | null;
  portraitSetId: number;
  processOptions?: PictureOptions;
};

export type ImageGenerationResultViewModel = {
  jobId: string;
  userId: number;
  pictureSetId: number | null;
  portraitSetId: number;
  editMode?: string;
  canceled: boolean;
  pictures: PictureViewModel[];
  foundationImage?: PictureViewModel;
  reason?: string;
  status?: PictureStatus;
  messageId?: number;
};

export type LatestPictureViewModel = Pick<PictureModel, 'id' | 'pictureUrl' | 'pictureThumbUrl' | 'userId' | 'portraitSetsId'> & {
  dateGenerated: Date;
  updateDate: Date;
  pictureCount: number;
  isPreview: boolean;
  status: PictureStatus;
};

export type JobCounterViewModel = {
  activeJobsCount: number;
  maxJobs: number;
};

export enum ChatGenerationStatus {
  pending = 'pending',
  fired = 'fired',
  completed = 'completed',
  failed = 'failed',
}
