export const detailEnhancerDefaultValue = Number(1);
export const similarityStrengthDefaultValue = Number(1);
export const activeRatioDefaultValue = '7:9';
export const renderModeDefaultValue = 'cinematic';
export const customInputDefaultValue = null;
export const haircutDefaultValue = null;
export const haircutNameDefaultValue = null;
export const hairColorDefaultValue = null;
export const eyeColorDefaultValue = null;
export const ageDefaultValue = null;
