/**
 * This file is the main export file for models.
 *
 * It is organized using index.ts files to provide a centralized and convenient way to export modules from subfolders.
 * By using index.ts files, we can easily import multiple modules from a subfolder using a single import statement.
 *
 * The line "export * from './subfolder'" exports all the modules from the 'subfolder' to the parent module.
 * This allows us to access the exported modules directly from the parent module without having to import each module individually.
 *
 * index.ts files in subfolders serve as entry points for exporting modules and help maintain a clean and modular code structure.
 *
 * For more information, please refer to the project's README file in the TypeScript section.
 *
 * @see https://github.com/terese08152/myportrait/blob/main/server-ts/README.md#typescript
 */

/** --------------IMPORTANT--------------
 * DO NOT USE @alias in the any file in the models folder
 * this can cause a build problem in the client
* @example How to use the models on the client
* import { Auth, Enums } from '@api-types';

 and then use it like this Auth.LoginRequest
*/

export * from './enums/index';
import * as AuthTypes from './authTypes';
import * as UserTypes from './userTypes';
import * as CoinPackagesTypes from './coinPackagesTypes';
import * as PictureTypes from './pictureTypes';
import * as SocketTypes from './socket';
import * as PortraitSetTypes from './portraitSetTypes';
import * as Errors from './errors';
import * as SupportTypes from './support';
import * as PaymentTypes from './paymentTypes';
import * as StoryTypes from './story/story';
export * from './common';
export {
  AuthTypes,
  UserTypes,
  CoinPackagesTypes,
  PictureTypes,
  PortraitSetTypes,
  SocketTypes,
  Errors,
  SupportTypes,
  PaymentTypes,
  StoryTypes,
};
